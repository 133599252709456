import React from 'react';

import { bookAppointment, getServices, getTimeSlots, updateLanguageAndEmail } from './ServerContext.functions';

import {
  AppointmentSummary,
  BookingRequest,
  BookingResponse,
  Category,
  Employee,
  missing,
  PossibleAppointmentsRequest,
  ServicesResponse,
  Settings,
  UpdateCustomer,
} from '../../Types.d';
import { SimpleDate } from '@idot-digital/calendar-api';

export interface ServerHook {
  categories: Category[] | missing;
  employees: Employee[] | missing;
  booking: AppointmentSummary | missing;
  namespaceNotFound: boolean;

  requestTimeSlots: (request: PossibleAppointmentsRequest) => Promise<void>;

  bookAppointment: (request: BookingRequest) => Promise<BookingResponse>;
  updateLanguageAndEmail: (request: UpdateCustomer) => Promise<void>;

  settings: Settings;
}

export const ServerContext = React.createContext<ServerHook>(undefined!);

export function useServer() {
  return React.useContext(ServerContext);
}

export function ServerProvider(props: { children: JSX.Element }) {
  const [categories, setCategories] = React.useState<Category[] | missing>();
  const [employees, setEmployees] = React.useState<Employee[] | missing>();
  const [booking, setBooking] = React.useState<AppointmentSummary | missing>();
  const [agbLink, setAgbLink] = React.useState<string>('');
  const [currencySign, setCurrencySign] = React.useState<string>('€');
  const [showPriceMode, setShowPriceMode] = React.useState<ServicesResponse['settings']['showPriceMode']>('always');
  const [bookingPeriod, setBookingPeriod] = React.useState<Settings['bookingPeriod']>({
    start: SimpleDate.now(),
    end: SimpleDate.now().add(0, 0, 30),
  });
  const [namespaceNotFound, setNamespaceNotFound] = React.useState<boolean>(false);

  const exportValue = {
    categories,
    employees,
    booking,
    namespaceNotFound,

    requestTimeSlots: async (request: PossibleAppointmentsRequest) => {
      const timeSlots = await getTimeSlots(request);
      setEmployees(timeSlots);
    },

    bookAppointment: async (request: BookingRequest) => {
      const booking = await bookAppointment(request);
      setBooking(booking.summary);

      return booking;
    },
    updateLanguageAndEmail,

    settings: {
      agbLink,
      currencySign,
      showPriceMode,
      bookingPeriod,
    },
  };

  //@ts-ignore
  window.server = exportValue;

  // Load initial data
  React.useEffect(() => {
    getServices().then((res) => {
      if (typeof res === 'string') {
        setNamespaceNotFound(true);
        return;
      }
      setNamespaceNotFound(false);
      const { categories, settings } = res;
      setCategories(categories);
      setAgbLink(settings.agbLink);
      setCurrencySign(settings.currencySign);
      setShowPriceMode(settings.showPriceMode);
      setBookingPeriod({
        start: SimpleDate.importInt(settings.bookingPeriodStart),
        end: SimpleDate.importInt(settings.bookingPeriodEnd),
      });
    });
  }, []);

  return <ServerContext.Provider value={exportValue}>{props.children}</ServerContext.Provider>;
}
