/**
 * Get header AppHeaderBar text by location
 * @param location pass location.pathname in order to check what text to show
 * @returns header text for AppHeaderBar
 */
export function getHeaderFromLocation(location: string): string {
  switch (true) {
    case location.startsWith('/summary'):
      return 'Buchungsübersicht';
    case location.startsWith('/completed'):
      return 'Fertig!';
    default:
      return 'Online Buchung';
  }
}
