import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

export default function Loading() {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: 2,
        boxSizing: 'border-box',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
