import { Box } from '@mui/system';

import ServiceTile from './ServiceTile';
import { useServer } from '../Server/ServerContext';
import Subtitle from '../AppHeadBar/Subtitle';
import appFunctions from '../../AppFunctions';

import { Styles } from '../../Types';

const styles = {
  servicesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: (theme) => theme.spacing(1),
    paddingTop: (theme) => theme.spacing(6),
    boxSizing: 'border-box',
    justifyContent: 'flex-end',
  },
} as Styles;

export default function ServicesWrapper() {
  const { category } = appFunctions.getSelection(useServer());

  return (
    <>
      <Subtitle>{category.name}</Subtitle>

      <Box sx={styles.servicesWrapper}>
        {category.services.map((service) => (
          <ServiceTile service={service} key={service.id} />
        ))}
      </Box>
    </>
  );
}
