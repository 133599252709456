import { NavigateFunction, Route, Routes, useNavigate } from 'react-router';

import { Box } from '@mui/system';

import AppHeadBar from './Components/AppHeadBar/AppHeadBar';

import CategoryWrapper from './Components/Categories/CategoryWrapper';
import ServicesWrapper from './Components/Services/ServicesWrapper';
import AdditionalServiceWrapper from './Components/AdditionalService/AdditionalServiceWrapper';
import AppointmentPickerWrapper from './Components/AppointmentPicker/AppointmentPickerWrapper';
import Summary from './Components/Summary/Summary';
import RegisterWrapper from './Components/Register/RegisterWrapper';
import Language from './Components/Language/Language';
import CompletedWrapper from './Components/Completed/CompletedWrapper';

import { Styles } from './Types';
import { Typography } from '@mui/material';
import { useServer } from './Components/Server/ServerContext';

const styles: Styles = {
  wrapper: {
    backgroundColor: (theme) => theme.palette.background.default,
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
    width: '100%',
    position: 'relative',
    maxHeight: {
      xs: 'calc(95vh - 3rem)',
      sm: 'calc(95vh - 5rem)',
    },
    minHeight: {
      xs: 'calc(10cm - 3rem)',
      sm: 'calc(10cm - 5rem)',
    },
    '& > *': {
      minHeight: {
        xs: 'calc(10cm - 3rem)',
        sm: 'calc(10cm - 5rem)',
      },
    },
  },
};

let navigate: NavigateFunction = () => {
  console.warn('Navigate not initialized');
};

export default function App() {
  const { namespaceNotFound } = useServer();
  const _navigate = useNavigate();
  navigate = _navigate;

  return (
    <>
      <AppHeadBar />
      <Box sx={styles.wrapper}>
        {window.emmasy_namespace && !namespaceNotFound ? (
          <Routes>
            <Route element={<CompletedWrapper />} path="/completed" />
            <Route element={<Language />} path="/language" />
            <Route element={<RegisterWrapper />} path="/book/*" />
            <Route element={<Summary />} path="/summary/:disableNext?" />
            <Route element={<AppointmentPickerWrapper />} path="/appointments" />
            <Route element={<AdditionalServiceWrapper />} path="/additionalServices" />
            <Route element={<ServicesWrapper />} path="/services" key={window.location.pathname} />
            <Route element={<CategoryWrapper />} path="/" />
          </Routes>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            padding={2}
            boxSizing="border-box"
          >
            <Typography variant="h4" textAlign="center" margin={0} marginBottom={2}>
              Fehler
            </Typography>
            {namespaceNotFound ? (
              <Typography textAlign="center">
                Der angegebene Namespace existiert nicht.
                <br />
                Bitte vergewissern Sie sich, dass Sie die richtige URL verwenden.
              </Typography>
            ) : (
              <Typography textAlign="center">
                Bitte geben Sie in der URL den Namespace an.
                <br /> (z.B.: https://customer.emmasy.com?namespace=123)
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </>
  );
}

export { navigate };
