import { Typography } from '@mui/material';

import { Styles } from '../../Types';

export interface SubtitleProps {
  children: JSX.Element | string;
}

const styles: Styles = {
  subtitle: {
    width: '100%',
    textAlign: 'left',
    padding: (theme) => `${theme.spacing(1)} ${theme.spacing(2)}`,
    boxSizing: 'border-box',
    position: 'absolute',
  },
};

export default function Subtitle(props: SubtitleProps) {
  return (
    <Typography variant="subtitle1" sx={styles.subtitle}>
      {props.children}
    </Typography>
  );
}
