import { Button, Typography, useMediaQuery } from '@mui/material';
import { Box, SxProps, Theme, useTheme } from '@mui/system';
import Icon from '@mdi/react';
import * as mdi from '@mdi/js';

import DiscountIcon from '../Discounts/DiscountIcon';
import appFunctions from '../../AppFunctions';

import { Category, Styles } from '../../Types';

const styles = {
  wrapper: {
    margin: (theme) => theme.spacing(1),
  },
  wrapperHorizontal: { paddingTop: (theme) => 'calc(25% - 2 * ' + theme.spacing(1) + ')', flex: '1 1 0' },
  wrapperVertical: {
    paddingTop: (theme) => ({
      sm: 'calc(50% - 2 * ' + theme.spacing(1) + ')',
    }),
    flex: (theme) => ({
      xs: '0 0 auto',
      sm: '1 1 calc(50% - 2 * ' + theme.spacing(1) + ')',
    }),
  },
  innerWrapper: {
    position: {
      xs: 'relative',
      sm: 'absolute',
    },
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    padding: (theme) => ({
      xs: theme.spacing(2, 2, 2, 0),
      sm: theme.spacing(2),
    }),
    boxSizing: 'border-box',
    alignItems: 'center',
  },
  innerWrapperVertical: {
    flexDirection: { xs: 'row-reverse', sm: 'column-reverse' },
    justifyContent: { xs: 'flex-end', sm: 'inherit' },
  },
  text: {
    color: (theme) => theme.palette.primary.contrastText,
    textAlign: 'center',
    flex: {
      xs: '1 1 auto',
      sm: 'inherit',
    },
    paddingRight: (theme) => ({
      xs: theme.spacing(2),
      sm: 0,
    }),
  },
  imageWrapper: {
    flex: {
      xs: '0 0 auto',
      sm: '1 1 0',
    },
    width: {
      sm: '80%',
    },
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
} as Styles;

export default function CategoryTile(props: { category: Category; horizontal?: boolean }) {
  const theme = useTheme();
  const mobile = !useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <Button
      sx={
        {
          ...styles.wrapper,
          ...(props.horizontal ? styles.wrapperHorizontal : styles.wrapperVertical),
        } as SxProps<Theme>
      }
      onClick={() => appFunctions.selectCategory(props.category)}
      color="primary"
      variant="contained"
    >
      {props.category.hasDiscount && <DiscountIcon />}
      <Box
        sx={
          {
            ...styles.innerWrapper,
            ...(props.horizontal ? {} : styles.innerWrapperVertical),
          } as SxProps<Theme>
        }
      >
        {props.horizontal ? (
          <>
            <Icon
              path={mdi[props.category.icon as keyof typeof mdi]}
              size={4}
              color={theme.palette.primary.contrastText}
            />
            <Typography variant="h6" sx={styles.text}>
              {props.category.name}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h6" sx={styles.text}>
              {props.category.name}
            </Typography>
            <Box sx={styles.imageWrapper}>
              <Icon
                path={mdi[props.category.icon as keyof typeof mdi]}
                size={mobile ? 3 : 5}
                color={theme.palette.primary.contrastText}
              />
            </Box>
          </>
        )}
      </Box>
    </Button>
  );
}
