import { Button, Typography } from '@mui/material';

import DiscountIcon from '../Discounts/DiscountIcon';
import PriceVisualizer from '../Discounts/PriceVisualizer';
import appFunctions from '../../AppFunctions';

import { Service, Styles } from '../../Types';

const styles: Styles = {
  wrapper: {
    padding: (theme) => theme.spacing(3),
    margin: (theme) => theme.spacing(1),
    flexDirection: 'column',
  },
};

export default function ServiceTile(props: { service: Service }) {
  return (
    <Button
      onClick={() => appFunctions.selectService(props.service)}
      sx={styles.wrapper}
      color="primary"
      variant="contained"
    >
      {props.service.hasDiscount && <DiscountIcon />}
      <Typography variant="h6" textAlign="center">
        {props.service.name}
      </Typography>
      <Typography variant="subtitle1" sx={{ color: (theme) => theme.palette.text.disabled }} textAlign="center">
        <PriceVisualizer price={props.service} context="service" />
      </Typography>
    </Button>
  );
}
