import { Theme, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';

import Loading from '../Loading/Loading';
import { useServer } from '../Server/ServerContext';
import CategoryTile from './CategoryTile';

export default function CategoryWrapper() {
  const { categories } = useServer();
  const mobile = !useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(1),
        display: 'flex',
        flexWrap: { sm: 'wrap' },
        width: '100%',
        boxSizing: 'border-box',
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
      }}
    >
      {categories ? (
        categories.length > 0 ? (
          categories.map((category, index) => (
            <CategoryTile
              category={category}
              key={category.id}
              horizontal={!mobile && index === categories.length - 1 && categories.length % 2 === 1}
            />
          ))
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center" width="100%">
            <Typography textAlign="center" variant="h6">
              Es sind noch keine Dienstleistungen angelegt
            </Typography>
          </Box>
        )
      ) : (
        <Loading />
      )}
    </Box>
  );
}
