import { Avatar, AvatarProps } from '@mui/material';
import { Employee } from '../../Types';
import config from '../../config';

export interface EmployeeAvatarProps {
  employee?: Pick<Employee, 'shortName' | 'image'> | null;
}

export default function EmployeeAvatar(props: EmployeeAvatarProps & AvatarProps) {
  const { employee, ...rest } = props;
  if (!employee) return <Avatar {...rest} />;
  return (
    <Avatar
      src={
        employee.image
          ? ((config.serverBaseLink.endsWith('/') ? config.serverBaseLink : config.serverBaseLink + '/') ||
              window.location.origin + '/') +
            config.filePath +
            employee.image
          : undefined
      }
      alt={employee.shortName}
      {...rest}
    >
      {getInitials(employee.shortName)}
    </Avatar>
  );
}

function getInitials(name: string) {
  const names = (name || '').split(' ');
  if (names.length > 1) return names[0].slice(0, 1) + names[names.length - 1].slice(0, 1);
  if (names.length === 1) return names[0].slice(0, 2);
  return '';
}
