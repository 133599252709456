import { Box } from '@mui/system';

import { Styles } from '../../Types';

export interface RootPopupProps {
  children?: JSX.Element | JSX.Element[];
}

const styles: Styles = {
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: '#00000080',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: {
      xs: '2vh 2% 0',
      sm: '5vh 5% 0',
    },
    height: {
      xs: '98vh',
      sm: '95vh',
    },
    width: {
      xs: '96%',
      sm: '90%',
    },
  },
  wrapperInner: {
    maxHeight: '100%',
    maxWidth: '100%',
    minHeight: 'min(100%, 10cm)',
    minWidth: 'min(100%, 15cm)',
    overflow: 'hidden',
    borderRadius: (theme) => theme.spacing(1),
  },
};

export default function RootPopup(props: RootPopupProps) {
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.wrapperInner}>{props.children}</Box>
    </Box>
  );
}
