import { useNavigate, useLocation } from 'react-router';

import { Box } from '@mui/system';
import { ArrowBack, Close } from '@mui/icons-material';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';

import appFunctions from '../../AppFunctions';
import { getHeaderFromLocation } from './AppHeadBarFunctions';

import { Styles } from '../../Types';

export interface AppHeadBarProps {
  header?: string;
}

const styles = {
  wrapper: {
    padding: (theme) => theme.spacing(1),
    height: {
      xs: '4rem',
      sm: '5rem',
    },
    overflow: 'hidden',
    borderRadius: (theme) => `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
  },
  toolbar: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    height: '100%',
    minHeight: '0',
  },
  header: {
    display: {
      xs: 'none',
      sm: 'initial',
    },
    flexGrow: 10,
  },
} as Styles;

export default function AppHeadBar(props: AppHeadBarProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const header = props.header || getHeaderFromLocation(location.pathname);

  return (
    <AppBar position="sticky" sx={styles.wrapper}>
      <Toolbar sx={styles.toolbar}>
        {(location.pathname as string) !== '/' &&
        !location.pathname.startsWith('/completed') &&
        !location.pathname.startsWith('/language') &&
        !location.pathname.startsWith('/book/updateMail') ? (
          <IconButton onClick={() => navigate(-1)} sx={{ color: (theme) => theme.palette.primary.contrastText }}>
            <ArrowBack fontSize="large" />
          </IconButton>
        ) : (
          <Box sx={{ width: (theme) => theme.spacing(5) }} />
        )}
        <Typography variant="h4" sx={styles.header}>
          {header}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={appFunctions.closeApp} sx={{ color: (theme) => theme.palette.primary.contrastText }}>
          <Close fontSize="large" />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
