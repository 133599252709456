import React from 'react';

import { Checkbox, TextField, Typography, Box, Link, styled, useMediaQuery, useTheme } from '@mui/material';

import { useServer } from '../Server/ServerContext';
import PhoneInput from './PhoneInput';
import { isEmailValid, isValidPhoneNumber } from './PhoneInput.functions';

export interface RegisterProps {
  onChange: (values: {
    name: string;
    phone: string;
    email: string;
    // createAccount: boolean;
    acceptAGB: boolean;
    valid: boolean;
  }) => void;
  isValid: (valid: boolean) => void;
  onCommit: () => void;
}

const TextWrapperDesktop = styled('div')(
  () => `
    display: flex;
    width: 100%;
    align-items: center;

    & > .MuiTextField-root, & > .MuiTypography-root, & > .inputWrapper {
        width: calc(50% - 1rem);
    };
    & > .MuiTypography-root {
        text-align: end;
        margin-right: 2rem;
    };
    & > .MuiCheckbox-root {
        padding: 4px;
    };
  `,
);

const TextWrapperMobile = styled('div')(
  () => `
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;

    & > .MuiCheckbox-root {
        padding: 4px;
    };
  `,
);

const CheckboxWrapperMobile = styled('div')(
  () => `
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem;

    & > .MuiCheckbox-root {
        padding: 4px;
    };
  `,
);

export default function Register(props: RegisterProps) {
  const theme = useTheme();
  const mobile = !useMediaQuery(theme.breakpoints.up('sm'));

  const TextWrapper = mobile ? TextWrapperMobile : TextWrapperDesktop;
  const CheckboxWrapper = mobile ? CheckboxWrapperMobile : TextWrapperDesktop;

  const { settings } = useServer();
  // Default null to prevent error on startup
  const [name, setName] = React.useState<string | null>(null);
  const [phone, setPhone] = React.useState<string>('');
  const [email, setEmail] = React.useState('');

  const [acceptAGB, setAcceptAGB] = React.useState(false);

  const valid = React.useMemo(
    () => Boolean(name) && isValidPhoneNumber(phone) && isEmailValid(email) && (!settings.agbLink || acceptAGB),
    [name, phone, email, settings, acceptAGB],
  );

  React.useEffect(() => {
    const valid = Boolean(name) && isValidPhoneNumber(phone) && isEmailValid(email) && (!settings.agbLink || acceptAGB);

    // Cleanup inputs
    props.onChange({
      name: (name || '').trim(),
      phone,
      email,
      // createAccount,
      acceptAGB,
      valid,
    });
    // Return if values are valid
    props.isValid(valid);
    // eslint-disable-next-line
  }, [name, email, phone, acceptAGB, props]);

  return (
    <Box width="100%">
      <TextWrapper>
        {!mobile && <Typography>Name</Typography>}
        <TextField
          value={name || ''}
          onChange={(event) => setName(event.target.value)}
          fullWidth
          error={name === ''}
          {...(mobile && { label: 'Name' })}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && valid) props.onCommit();
          }}
        />
      </TextWrapper>
      <TextWrapper>
        {!mobile && <Typography>Handynummer</Typography>}
        <Box className="inputWrapper">
          <PhoneInput
            value={phone}
            onChange={setPhone}
            phoneInputProps={() => ({ ...(mobile && { label: 'Handynummer' }) })}
            countryInputProps={({ focused }) => ({ ...(mobile && { label: focused ? 'Vorwahl' : ' ' }) })}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && valid) props.onCommit();
            }}
          />
        </Box>
      </TextWrapper>
      <TextWrapper>
        {!mobile && <Typography>Email:</Typography>}
        <TextField
          value={email}
          onChange={(event) => setEmail(event.target.value.toLowerCase())}
          fullWidth
          error={email !== '' && !isEmailValid(email)}
          {...(mobile && { label: 'Email' })}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && valid) props.onCommit();
          }}
        />
      </TextWrapper>
      {settings.agbLink && (
        <CheckboxWrapper>
          <Typography>
            {'Ich stimme den '}
            <Link href={settings.agbLink} target="_blank" rel="noopener noreferrer">
              AGB
            </Link>
            {' zu:'}
          </Typography>
          <Checkbox checked={acceptAGB} onChange={(_, checked) => setAcceptAGB(checked)} />
        </CheckboxWrapper>
      )}
    </Box>
  );
}
