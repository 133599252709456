import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

const phoneValidator = PhoneNumberUtil.getInstance();

class NoPhoneNumberError extends Error {
  constructor() {
    super('Telefonnummer ist nicht gültig');
  }
}

/**
 * Parse input to phone number object
 * @param input to be parsed
 * @returns PhoneNumber object or thrown NoPhoneNumberError
 */
export const parsePhone = (input: string): PhoneNumber => {
  // Replace leading "0" with national "+49" if present
  if (input.startsWith('0')) input = '+49' + input.substring(1);

  try {
    // Catch error when parsing invalid phone number
    return phoneValidator.parse(input);
  } catch (_) {
    throw new NoPhoneNumberError();
  }
};

/**
 * Parse phone number from string
 * @param input to be parsed
 * @returns parsed phone number or thrown NoPhoneNumberError
 */
export const parsePhoneNumber = (input: string): string => {
  return phoneValidator.format(parsePhone(input), PhoneNumberFormat.INTERNATIONAL);
};

/**
 * Check if phone number is valid
 * @param input to be checked
 * @returns true, if input is a valid phone number
 */
export const isValidPhoneNumber = (input: string): boolean => {
  try {
    return phoneValidator.isValidNumber(parsePhone(input));
  } catch (_) {
    return false;
  }
};

export const isEmailValid = (email: string): boolean => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9]+$/i.test(email);
};
