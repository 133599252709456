import React from 'react';

import { Box, useTheme } from '@mui/system';
import { Button, Typography } from '@mui/material';

import PriceVisualizer from '../Discounts/PriceVisualizer';
import appFunctions from '../../AppFunctions';

import { Employee, Styles } from '../../Types';
import Loading from '../Loading/Loading';

export interface AppointmentListProps {
  filteredEmployees: Employee[];
  loading?: boolean;
}

const styles = {
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: (theme) => theme.spacing(1),
    boxSizing: 'border-box',
    width: {
      xs: '100%',
      sm: 'min(52rem, 90vw)',
    },
  },
  timeslot: {
    padding: (theme) => theme.spacing(2),
    width: (theme) => ({
      xs: `calc(100% - 2 * ${theme.spacing(1)})`,
      sm: `calc(50% - 2 * ${theme.spacing(1)})`,
    }),
    margin: (theme) => theme.spacing(1),
    minHeight: '5rem',
  },
  timeslotText: {
    display: 'inline-block',
    alignItems: 'center',
    paddingRight: (theme) => theme.spacing(1),
  },
  noTimeWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: (theme) => theme.spacing(4),
  },
} as Styles;

export default function AppointmentList(props: AppointmentListProps) {
  const theme = useTheme();

  const noAvailableTimeSlot = React.useMemo(() => {
    return !props.filteredEmployees.some((employee) => employee.timeSlots.length);
  }, [props.filteredEmployees]);

  if (props.loading)
    return (
      <Box sx={styles.wrapper}>
        <Box sx={styles.noTimeWrapper}>
          <Loading />
        </Box>
      </Box>
    );

  if (noAvailableTimeSlot)
    return (
      <Box sx={styles.wrapper}>
        <Box sx={styles.noTimeWrapper}>
          <Typography variant="h6">
            {props.filteredEmployees.length
              ? 'Die ausgewählten Mitarbeiter haben an dem Tag keine Termine mehr frei'
              : 'An dem ausgewählten Tag sind keine Termine mehr frei'}
          </Typography>
        </Box>
      </Box>
    );

  return (
    <Box sx={styles.wrapper}>
      {props.filteredEmployees.map((employee) =>
        employee.timeSlots.map((timeSlot) => (
          <Button
            sx={styles.timeslot}
            // Generate unique key for each timeslot
            key={employee.id + timeSlot.startTime.exportInt()}
            onClick={() => appFunctions.selectTimeSlot(employee, timeSlot)}
            color="primary"
            variant="contained"
          >
            <Box sx={styles.timeslotText}>
              <Typography sx={styles.timeslotText}>
                {`${timeSlot.startTime.getTimeString()} Uhr bei ${employee.shortName}`}
              </Typography>
              <PriceVisualizer
                price={timeSlot}
                color={theme.palette.text.disabled}
                finalPrice
                context="appointment_list"
              />
            </Box>
          </Button>
        )),
      )}
    </Box>
  );
}
