import React from 'react';

import { Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';

import { Service, Styles } from '../../Types';

import Marquee from 'react-fast-marquee';
import DiscountSVG from './Discount';

export interface DiscountMarqueeProps {
  discountTexts: NonNullable<Service['discountTexts']>;
}

const styles: Styles = {
  outer: {
    width: '100%',
    position: 'sticky',
    minHeight: '3rem',
    height: '3rem',
    overflow: 'hidden',
    top: 0,
    zIndex: 2,
  },
  wrapper: {
    position: 'absolute',
    display: 'flex',
    background: (theme) => theme.palette.error.main,
    width: '100%',
    height: '100%',
  },
  icon: {
    height: '3rem',
    width: '3rem',
    flex: '0 0 3rem',
  },
};

export default function DiscountMarqee(props: DiscountMarqueeProps) {
  const theme = useTheme();

  const discountTexts = React.useMemo(() => {
    return props.discountTexts.filter((text) => text.length);
  }, [props.discountTexts]);

  if (discountTexts.length === 0) return <></>;

  return (
    <Box sx={styles.outer}>
      <Box sx={styles.wrapper}>
        <Box sx={styles.icon}>
          <DiscountSVG fill={theme.palette.primary.contrastText} />
        </Box>
        <Marquee gradient={false} delay={1}>
          {discountTexts.map((discount) => (
            <Typography key={discount} variant="h6" marginRight={3} color="textPrimary">
              {discount}
            </Typography>
          ))}
        </Marquee>
      </Box>
    </Box>
  );
}
