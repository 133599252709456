import React from 'react';

import { Autocomplete, StandardTextFieldProps, TextField, TextFieldProps } from '@mui/material';
import { Box } from '@mui/system';

import { findCountry, MappedCountries, mappedCountries } from './Countries';
import { Styles } from '../../Types';
import { isValidPhoneNumber, parsePhoneNumber } from './PhoneInput.functions';
import config from '../../config';

const styles = {
  wrapper: {
    display: 'flex',
    position: 'relative',
  },
  collapse: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '3.5rem',
    overflow: 'hidden',
    transition: (theme) => (theme.transitions as any).create('flex-basis'),
  },
  country: {
    position: 'absolute',
    width: '100%',
    left: 0,
    top: 0,
  },
  countryInner: {
    // Offset country input clear and collapse buttons
    '& .MuiAutocomplete-endAdornment': {
      left: 'calc(80% - 28px * 2)',
    },
  },
  phone: {
    paddingLeft: (theme) => theme.spacing(1),
    flex: '1 1 0',
    background: (theme) => theme.palette.background.default,
    '& .MuiInputLabel-root': {
      paddingLeft: (theme) => theme.spacing(1),
    },
  },
} as Styles;

export interface PhoneInputProps extends Omit<StandardTextFieldProps, 'value' | 'onChange'> {
  value?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  countryInputProps?: (state: { focused: boolean }) => TextFieldProps;
  phoneInputProps?: (state: {}) => TextFieldProps;
}

export default function PhoneInput({
  value,
  defaultValue,
  onChange,
  countryInputProps,
  phoneInputProps,
  ...rest
}: PhoneInputProps) {
  const [countryCode, setCountryCode] = React.useState<null | MappedCountries>(
    mappedCountries.find((country) => country.code === 'DE') || null,
  );
  const [phone, setPhone] = React.useState('');

  const [focused, setFocused] = React.useState(false);
  const phoneInput = React.useRef<HTMLElement | null>(null);

  const parse = (countryCode: null | MappedCountries, phone: string) => `${countryCode?.phone || ''} ${phone.trim()}`;

  const update = React.useCallback((input: string, setFlag?: boolean): string => {
    try {
      // Try parsing number
      const number = parsePhoneNumber(input);

      if (!isValidPhoneNumber(number)) throw new Error();

      const countryCode = findCountry(number);
      const phone = number.replace(countryCode?.phone || '', '').trim();

      // Dont update internal states in controlled mode
      if (setFlag || value === undefined) {
        setCountryCode(countryCode);
        setPhone(phone);
      }

      return parse(countryCode, phone);
    } catch (_) {
      // Otherwise just update values
      const countryCode = findCountry(input);
      const phone = input.replace(countryCode?.phone || '', '').trim();

      // Dont update internal states in controlled mode
      if (setFlag || value === undefined) {
        setCountryCode(countryCode);
        setPhone(phone);
      }

      return parse(countryCode, phone);
    }
    // eslint-disable-next-line
  }, []);

  const error = React.useMemo(
    () => Boolean(phone.length) && !isValidPhoneNumber(parse(countryCode, phone)),
    [countryCode, phone],
  );

  // Uncontrolled mode setting default value once
  React.useEffect(
    () => {
      if (defaultValue) update(defaultValue);
    },
    // Set default value only once
    // eslint-disable-next-line
    [],
  );

  // Controlled mode updating internal values
  React.useEffect(() => {
    if (value) update(value, true);
  }, [update, value]);

  return (
    <Box sx={styles.wrapper}>
      <Box
        sx={{
          ...styles.collapse,
          ...(focused
            ? { flexBasis: '80%' }
            : {
                flexBasis: `${(countryCode?.phone.length || 3) * 0.5 + 1}rem`,
              }),
        }}
      >
        <Autocomplete
          sx={styles.country}
          value={countryCode}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          onChange={(_, countryCode) => {
            onChange(update(parse(countryCode, phone)));
            if (countryCode !== null) phoneInput.current?.focus();
          }}
          options={mappedCountries}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          renderInput={(params) => (
            <TextField sx={styles.countryInner} {...countryInputProps?.({ focused })} {...params} />
          )}
          autoHighlight
          getOptionLabel={(option) => (focused ? `${option.phone} ${option.label}` : `${option.phone}`)}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...props}
              key={option.phone + option.label}
            >
              <img
                loading="lazy"
                width="20"
                src={`${config.flagsBaseURL}w20/${option.code.toLowerCase()}.png`}
                srcSet={`${config.flagsBaseURL}w40/${option.code.toLowerCase()}.png 2x`}
                alt={option.label}
              />
              {option.label} ({option.code}) {option.phone}
            </Box>
          )}
          fullWidth
        />
      </Box>

      <TextField
        sx={styles.phone}
        value={phone}
        onChange={(event) =>
          onChange(update(parse(countryCode, (event.target as HTMLInputElement).value.replace(/[^0-9]+/g, ''))))
        }
        error={error}
        fullWidth
        inputRef={(ref) => (phoneInput.current = ref)}
        {...phoneInputProps?.({ focused })}
        {...rest}
      />
    </Box>
  );
}
