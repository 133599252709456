import React from 'react';

import { Button, CircularProgress, MenuItem, Select, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { useServer } from '../Server/ServerContext';
import config from '../../config';

import { Styles } from '../../Types';
import appFunctions from '../../AppFunctions';

const styles: Styles = {
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: (theme) => theme.spacing(2),
  },
  select: {
    minWidth: '50%',
    margin: (theme) => theme.spacing(2, 0),
  },
  button: {
    fontSize: '1.25em',
    padding: (theme) => theme.spacing(2) + ' ' + theme.spacing(3),
    width: '100%',
    justifyContent: 'flex-start',
  },
};

export default function Language() {
  const server = useServer();

  const [language, setLanguage] = React.useState<string>(config.languages[0].name);
  const [error, setError] = React.useState<string | undefined>();
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <Box sx={styles.wrapper}>
      <Typography>Welche Sprache sprechen Sie?</Typography>
      <Select
        variant="standard"
        value={language}
        sx={styles.select}
        onChange={(e) => {
          setLanguage(e.target.value);
        }}
      >
        {config.languages.map((l) => (
          <MenuItem key={l.name} value={l.name}>
            {l.icon ? (
              <img
                src={l.icon}
                alt={l.name}
                width="20px"
                style={{
                  marginRight: '10px',
                }}
              />
            ) : (
              <Box width="20" marginRight="10" />
            )}
            {l.name}
          </MenuItem>
        ))}
      </Select>
      <Typography color="error">{error || ''}</Typography>
      <Button
        variant="contained"
        fullWidth
        size="large"
        disabled={loading}
        sx={styles.button}
        onClick={() => appFunctions.updateLanguage({ language, useServer: server, setLoading, setError })}
      >
        {loading ? <CircularProgress /> : 'Buchung abschließen'}
      </Button>
    </Box>
  );
}
