import { SimpleDate } from '@idot-digital/calendar-api';
import {
  BookingRequest,
  BookingResponse,
  PossibleAppointmentsRequest,
  PossibleAppointmentsResponse,
  ServicesResponse,
  UpdateCustomer,
} from '../../Types.d';
import Server from './GenericServer';

/* ---------- Selection Process ---------- */

/**
 * Get necessary data for the selection process. This includes all categories, services and additional services.
 */
export async function getServices(): Promise<ServicesResponse | 'Namespace not found'> {
  try {
    const res = await Server.get<ServicesResponse | 'Namespace not found'>('/open/:namespace/services', {
      params: {
        namespace: window.emmasy_namespace,
      },
    });
    if (typeof res !== 'object') return res;

    return {
      ...res,
      categories: res.categories.sort((a, b) => a.index - b.index),
    };
  } catch (e) {
    console.log(e);
    return 'Namespace not found';
  }
}

/**
 * Get all employees and their available time slots for a specific date.
 * @param request The request object, including the category, service and additional services.
 */
export async function getTimeSlots(request: PossibleAppointmentsRequest): Promise<PossibleAppointmentsResponse> {
  const timeslots = await Server.post<PossibleAppointmentsResponse>('/open/:namespace/timeslots', {
    params: {
      namespace: window.emmasy_namespace,
    },
    body: {
      serviceid: request.serviceId,
      date: request.date.exportInt(),
      additional_serviceids: request.additionalServiceIds,
    },
  });

  return timeslots.map((employee) => ({
    ...employee,
    timeSlots: employee.timeSlots.map((timeslot) => ({
      ...timeslot,
      startTime: SimpleDate.importInt(timeslot.startTime as unknown as number),
    })),
  }));
}

/* ---------- Booking Process ---------- */

/**
 * Book an appointment.
 * @param request The request object, including the category, service, additional services, employee and time slot.
 */
export async function bookAppointment(request: BookingRequest): Promise<BookingResponse> {
  const res = await Server.post<BookingResponse>('/open/:namespace/book', {
    params: {
      namespace: window.emmasy_namespace,
    },
    body: {
      serviceid: request.serviceId,
      start_time: request.startTime.exportInt(),
      additional_serviceids: request.additionalServiceIds,
      employeeid: request.employeeId,
      name: request.name,
      email: request.email,
      phone: request.phone,
    },
  });

  if (typeof res !== 'object') throw new Error('Beim Buchen ist ein Fehler aufgetreten.');

  return {
    ...res,
    summary: {
      ...res.summary,
      startDate: SimpleDate.importInt(res.summary.startDate as unknown as number),
    },
  };
}

/**
 * Update the customer data if the customer is already known with different data.
 * @param customer The customer object, including the phone number, email and/or language.
 */
export async function updateLanguageAndEmail(customer: UpdateCustomer): Promise<void> {
  await Server.post('/open/:namespace/updateAccount', {
    params: {
      namespace: window.emmasy_namespace,
    },
    body: {
      phone: customer.phone,
      email: customer.email,
      language: customer.language,
    },
  });
}
