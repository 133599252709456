import { useTheme } from '@mui/material';
import { Box } from '@mui/system';

import { Styles } from '../../Types';
import DiscountSVG from './Discount';

const styles: Styles = {
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '4rem',
    width: '4rem',
    overflow: 'hidden',
    zIndex: 2,
    // needs to be theme borderradius bcs mui only multiplies borderradius
    // with theme borderRadius for bordeRadius prop and not borderTopRightRadius prop
    // (for borderRadius borderRadius: 1 means 1 * theme.shape.borderRadius)
    borderTopRightRadius: (theme) => theme.shape.borderRadius,
  },
  inner: {
    background: (theme) => theme.palette.error.main,
    transform: 'rotate(45deg)',
    height: '150%',
    width: '150%',
    position: 'absolute',
    top: '-80%',
    right: '-80%',
    zIndex: -1,
  },
};

export default function DiscountIcon() {
  const theme = useTheme();

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.inner}></Box>
      <DiscountSVG
        fill={theme.palette.primary.contrastText}
        style={{ padding: '0 0 50% 50%', boxSizing: 'border-box' }}
      />
    </Box>
  );
}
