import React from 'react';

import { CheckRounded } from '@mui/icons-material';
import { Button, Box, Typography } from '@mui/material';

import Loading from '../Loading/Loading';
import Register from './Register';
import { useServer } from '../Server/ServerContext';
import appFunctions from '../../AppFunctions';

import { Styles } from '../../Types';

const styles = {
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: (theme) => ({
      xs: theme.spacing(0, 2, 2, 2),
      sm: theme.spacing(2),
    }),
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  buttonWrapper: {
    height: '100%',
    width: '100%',
    marginTop: (theme) => theme.spacing(2),

    '& > *': {
      marginTop: 'auto',
    },
  },
  horizontalButtonWrapper: {
    height: '100%',
    width: '100%',
    marginTop: (theme) => theme.spacing(2),
    display: 'flex',
  },
  spacer: {
    width: (theme) => theme.spacing(2),
  },
  button: {
    fontSize: '1.25em',
    padding: (theme) => theme.spacing(2) + ' ' + theme.spacing(3),
    width: '100%',
    justifyContent: 'flex-start',
  },
  error: {
    textAlign: 'center',
  },
  emailHelperText: {
    textAlign: 'center',
  },
} as Styles;

export default function RegisterWrapper() {
  const server = useServer();

  const [valid, setValid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [customerAlreadyExistsError, setCustomerAlreadyExistsError] = React.useState(false);

  const login = React.useRef<{
    name: string;
    phone: string;
    email: string;
    // createAccount: boolean;
    acceptAGB: boolean;
    valid: boolean;
  }>(undefined!);

  function commit() {
    appFunctions.bookAppointment({
      customer: login.current,
      useServer: server,
      setLoading,
      setError,
      canUpdateMail: () => setCustomerAlreadyExistsError(true),
    });
  }

  return (
    <>
      {loading && <Loading />}
      <Box sx={{ ...styles.wrapper, ...(loading && { display: 'none' }) }}>
        <Register onChange={(values) => (login.current = values)} isValid={setValid} onCommit={commit} />
        <Typography sx={styles.error} variant="subtitle2" color="error">
          {error}
        </Typography>
        {!customerAlreadyExistsError ? (
          <Box sx={styles.buttonWrapper}>
            <Button
              variant="contained"
              size="large"
              sx={styles.button}
              disabled={!valid}
              startIcon={<CheckRounded />}
              onClick={commit}
            >
              Terminbuchung bestätigen
            </Button>
          </Box>
        ) : (
          <Box sx={styles.buttonWrapper}>
            <Typography sx={styles.emailHelperText} variant="subtitle2" color="error">
              Die Telefonnummer ist bereits mit einer anderen E-Mail-Adresse verknüpft.
            </Typography>
            <Typography sx={styles.emailHelperText} variant="subtitle2" color="error">
              Möchten Sie die E-Mail-Adresse aktualisieren?
            </Typography>
            <Box sx={styles.horizontalButtonWrapper}>
              <Button
                variant="contained"
                size="large"
                sx={styles.button}
                disabled={!valid}
                onClick={() => appFunctions.doNotUpdateEmail()}
              >
                E-Mail beibehalten
              </Button>
              <Box sx={styles.spacer} />
              <Button
                variant="contained"
                size="large"
                sx={styles.button}
                disabled={!valid}
                onClick={() => appFunctions.updateEmail({ ...login.current, useServer: server, setLoading, setError })}
              >
                E-Mail aktualisieren
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
