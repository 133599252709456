import { useParams } from 'react-router';

import { Box, useTheme } from '@mui/system';
import { ArrowRightAlt } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';

import PriceVisualizer from '../Discounts/PriceVisualizer';
import { useServer } from '../Server/ServerContext';

import { Styles } from '../../Types';
import appFunctions from '../../AppFunctions';
import EmployeeAvatar from '../AppointmentPicker/EmployeeAvatar';

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    padding: (theme) => theme.spacing(1),
    '>*': {
      margin: (theme) => `${theme.spacing(1)} 0`,
    },
  },
  rowWrapper: {
    display: 'flex',
    width: (theme) => `calc(100% - ${theme.spacing(1)} * 2)`,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dateTimeWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  dateTimeInner: {
    padding: (theme) => theme.spacing(0.5),
    border: (theme) => `${theme.palette.primary.main} solid ${theme.spacing(0.25)}`,
    borderRadius: 1,
    margin: (theme) => '0 ' + theme.spacing(1),
    flex: '1 0 0',
    textAlign: 'center',
  },
  employeeWrapper: {
    display: 'flex',
    alignItems: 'center',
    '>*': {
      margin: (theme) => '0 ' + theme.spacing(1),
    },
  },
  price: {
    margin: (theme) => '0 ' + theme.spacing(2),
  },
  employeeName: {
    textAlign: 'right',
  },
  continueButton: {
    fontSize: '1.25em',
    padding: (theme) => theme.spacing(2) + ' ' + theme.spacing(3),
    width: (theme) => `calc(100% - ${theme.spacing(1)} * 2)`,
    justifyContent: 'flex-start',
    margin: (theme) => theme.spacing(1),
  },
  image: {
    width: '4rem',
    height: '4rem',
  },
  left: {
    transform: 'rotate(180deg)',
  },
} as Styles;

export default function Summary() {
  const { disableNext } = useParams<{ disableNext: 'true' | 'false' | undefined }>();
  const theme = useTheme();
  const { category, service, additionalServices, employee, timeSlot } = appFunctions.getSelection(useServer());

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.rowWrapper}>
        <Typography textAlign="left" marginTop={1} marginBottom={1}>
          {category.name}
        </Typography>
        <Typography textAlign="right" marginTop={1} marginBottom={1}>
          {service.name}
        </Typography>
      </Box>
      {additionalServices.length > 0 && (
        <Box sx={styles.rowWrapper}>
          <Box />
          <Typography textAlign="right" marginBottom={1}>
            {'+ ' + additionalServices.map((service) => service.name).join(', ')}
          </Typography>
        </Box>
      )}
      <Box sx={styles.dateTimeWrapper}>
        <Box sx={styles.dateTimeInner}>
          <Typography>{timeSlot.startTime.getDateString()}</Typography>
        </Box>
        <Typography>um</Typography>
        <Box sx={styles.dateTimeInner}>
          <Typography>{`${timeSlot.startTime.getTimeString()} Uhr`}</Typography>
        </Box>
      </Box>
      <Box sx={styles.rowWrapper}>
        <Typography variant="h5" sx={styles.price}>
          <PriceVisualizer price={timeSlot} color={theme.palette.text.primary} finalPrice context="summary" />
        </Typography>
        <Box sx={styles.employeeWrapper}>
          <Typography variant="h6" sx={styles.employeeName}>{`bei ${employee.shortName}`}</Typography>
          <EmployeeAvatar employee={employee} sx={styles.image} />
        </Box>
      </Box>

      <Button
        sx={styles.continueButton}
        variant="contained"
        onClick={() => (disableNext !== 'true' ? appFunctions.applySelection() : appFunctions.exitSummary())}
      >
        {disableNext !== 'true' ? (
          <>
            <ArrowRightAlt fontSize="large" />
            Weiter
          </>
        ) : (
          <>
            <ArrowRightAlt sx={styles.left} fontSize="large" />
            Zurück
          </>
        )}
      </Button>
    </Box>
  );
}
