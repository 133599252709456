import React from 'react';

import { Typography } from '@mui/material';

import { Price, Styles } from '../../Types';
import { useServer } from '../Server/ServerContext';

export interface PriceVisualizerProps {
  price: Price;
  color?: string;
  finalPrice?: boolean;
  context: 'service' | 'additional_service' | 'summary' | 'appointment_list' | 'employee_picker';
}

const styles: Styles = {
  default: {
    fontSize: '1em',
  },
  disabled: {
    color: (theme) => theme.palette.error.main,
    textDecoration: 'line-through',
    fontSize: '1em',
    marginRight: (theme) => theme.spacing(1),
  },
  discounted: {
    fontSize: '1em',
  },
  surcharge: {
    fontSize: '1em',
  },
};

export default function PriceVisualizer(props: PriceVisualizerProps) {
  const { settings } = useServer();

  const showPrice = React.useMemo(() => {
    if (settings.showPriceMode === 'always') return true;
    if (settings.showPriceMode === 'never') return false;
    switch (props.context) {
      case 'service':
        return 'hide_additional' === settings.showPriceMode;
      case 'additional_service':
        return false;
      case 'summary':
        return true;
      case 'appointment_list':
        return 'hide_additional' === settings.showPriceMode;
      case 'employee_picker':
        return 'hide_additional' === settings.showPriceMode;
      default:
        return false;
    }
  }, [props.context, settings.showPriceMode]);

  if (!showPrice) return null;

  return (
    <>
      {!props.finalPrice && (
        <Typography component="span" sx={styles.default} color={props.color} marginRight={0.5}>
          ab
        </Typography>
      )}
      {!props.price.discountPrice || props.price.discountPrice === props.price.price ? (
        <Typography component="span" sx={styles.default} color={props.color}>
          {settings.currencySign + (props.price.price / 100).toFixed(2).replace('.', ',')}
        </Typography>
      ) : (
        <>
          <Typography component="span" sx={styles.disabled}>
            {settings.currencySign + (props.price.price / 100).toFixed(2).replace('.', ',')}
          </Typography>
          <Typography component="span" sx={styles.discounted} color={props.color}>
            {settings.currencySign + (props.price.discountPrice / 100).toFixed(2).replace('.', ',')}
          </Typography>
        </>
      )}
    </>
  );
}
