import { Button, Typography } from '@mui/material';

import appFunctions from '../../AppFunctions';

import { AdditionalService, Styles } from '../../Types';
import PriceVisualizer from '../Discounts/PriceVisualizer';

const styles: Styles = {
  wrapper: {
    padding: (theme) => theme.spacing(3),
    margin: (theme) => theme.spacing(1),
    flexDirection: 'column',
  },
};

export default function AdditionalServiceTile(props: { additionalService: AdditionalService }) {
  return (
    <Button
      onClick={() => appFunctions.toggleAdditionalService(props.additionalService)}
      sx={styles.wrapper}
      color="primary"
      variant="contained"
    >
      <Typography variant="h6" textAlign="center">
        {props.additionalService.name}
      </Typography>
      <Typography variant="subtitle1" sx={{ color: (theme) => theme.palette.text.disabled }} textAlign="center">
        <PriceVisualizer price={props.additionalService} context="additional_service" />
      </Typography>
    </Button>
  );
}
